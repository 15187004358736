<template>
  <b-row>
    <b-col md="3" class="mb-3">
      <!-- @input="adicionarValorTotal" -->
      <input-number
        ref="valorBruto"
        v-model="form.valorBruto"
        :label="$t(`SUPRIMENTOS_LANCAMENTOS_NF.VALOR_BRUTO`)"
        placeholder="SUPRIMENTOS_LANCAMENTOS_NF.VALOR_BRUTO"
        required
        min="0"
        decimal
        :key="keyAtualizaValorBruto"
        @change="alterarBotaoParaRecalcular"
      />
    </b-col>
    <b-col md="3" class="mb-3">
      <!-- @input="removerValorTotal" -->
      <input-number
        ref="descontos"
        v-model="form.descontos"
        :label="$t(`SUPRIMENTOS_LANCAMENTOS_NF.VALOR_DESCONTOS`)"
        placeholder="SUPRIMENTOS_LANCAMENTOS_NF.DESCONTOS"
        min="0"
        decimal
        :max="this.form.valorTotal"
        @change="alterarBotaoParaRecalcular"
      />
    </b-col>
    <b-col md="6" class="mb-3">
      <input-btn
        v-model="valorTotalImpostos"
        :label="$t(`SUPRIMENTOS_LANCAMENTOS_NF.VALOR_IMPOSTOS`)"
        placeholder="SUPRIMENTOS_LANCAMENTOS_NF.VALOR_IMPOSTOS"
        btnAppend="SUPRIMENTOS_LANCAMENTOS_NF.VISUALIZAR"
        readonly
        @btn-emit="irParaTab(2)"
        min="0"
        @change="alterarBotaoParaRecalcular"
      />
    </b-col>
    <b-col md="4" class="mb-3">
      <input-number
        ref="valorTotal"
        v-model="form.valorTotal"
        :label="$t(`SUPRIMENTOS_LANCAMENTOS_NF.VALOR_TOTAL_DA_NOTA_FISCAL`)"
        placeholder="SUPRIMENTOS_LANCAMENTOS_NF.VALOR_TOTAL_DA_NOTA_FISCAL"
        required
        min="0"
        decimal
        @change="alterarBotaoParaRecalcular"
      />
    </b-col>
    <b-col md="8">
      <input-select-multiple
        ref="historicoLancamento"
        v-model="form.historicoLancamento"
        :label="$t('FINANCEIRO.HISTORICO_LANCAMENTO')"
        :options="opcoes.historicoLancamento"
        class="mb-3"
        @change="alterarBotaoParaRecalcular"
        required
      />
    </b-col>
    <b-col md="2" class="mb-3">
      <input-select-multiple
        ref="formaPagamento"
        v-model="form.formaPagamento"
        :value="form.formaPagamento"
        :label="$t('FINANCEIRO.FORMA_PAGAMENTO')"
        :options="opcoes.formaPagamento"
        @input="alterarBotaoParaRecalcular"
        required
      />
    </b-col>
    <b-col
      md="2"
      v-if="form.formaPagamento && form.formaPagamento.value == 'Parcelado'"
    >
      <input-number
        ref="numeroParcelas"
        v-model="form.numeroParcelas"
        :label="$t(`SUPRIMENTOS_LANCAMENTOS_NF.NUMERO_PARCELAS`)"
        placeholder="SUPRIMENTOS_LANCAMENTOS_NF.NUMERO_PARCELAS"
        min="2"
        decimal
        @change="alterarBotaoParaRecalcular"
        required
      />
    </b-col>
    <b-col md="4">
      <b-button
        class="botao-acao-filtro w-100 mb-3"
        variant="primary"
        @click="calcularParcelas"
      >
        {{ textoBtn }}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
import HistoricoLancamentoService from '@/common/services/historico-lancamento/historico-lancamento.service.js';

// Components:
import {
  InputBtn,
  InputNumber,
  InputSelectMultiple,
} from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
    valorTotalImpostos: { type: String, default: String },
    keyAtualizaValorBruto: { type: Number, default: 1 },
  },
  components: {
    InputBtn,
    InputNumber,
    InputSelectMultiple,
  },
  mounted() {
    this.getHistoricosLancamento();
    this.getFormaPagamento();
  },
  data() {
    return {
      opcoes: {
        historicoLancamento: [],
        formaPagamento: [],
      },
      textoBtn: 'Calcular valor para pagamento',
      formaPagamento: null,
    };
  },
  watch: {},
  methods: {
    getFormaPagamento() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('forma-pagamento-nf')
        .then(({ data }) => {
          data = data.map((row) => ({
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.formaPagamento = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getHistoricosLancamento() {
      this.$store.dispatch(START_LOADING);
      HistoricoLancamentoService.obterEnumContasPagar()
        .then(({ data }) => {
          this.opcoes.historicoLancamento = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    calcularParcelas() {
      if (!this.validarFormulario()) return;

      if (this.form.formaPagamento?.value == 'AVista') {
        this.form.numeroParcelas = 1;
      }
      let parcelasGeradas = [];

      let valorParcela = this.form.valorTotal / this.form.numeroParcelas;
      const dataCalculoParcelas = this.form.dataEmissao.split('-');
      const [ano, mes, dia] = dataCalculoParcelas.map(Number);

      for (let i = 0; i < this.form.numeroParcelas; i++) {
        let dataParcela = new Date(ano, mes + i, dia);

        let verificaDiaSemana = dataParcela.getDay();
        if (verificaDiaSemana == 6) {
          dataParcela.setDate(dataParcela.getDate() + 2);
        }
        if (verificaDiaSemana == 0) {
          dataParcela.setDate(dataParcela.getDate() + 1);
        }

        let dataParcelaString = helpers.formatarDataUtc(dataParcela, false);
        parcelasGeradas.push({
          dataLancamento: this.form.dataEmissao,
          dataVencimento: dataParcelaString,
          valorDocumento: valorParcela.toFixed(2),
        });
      }

      let valorDiferencaValorParcelasValorTotal = 0;
      let calculoValidacaoValorParcelas = 0;

      parcelasGeradas.forEach((item) => {
        calculoValidacaoValorParcelas += Number(item.valorDocumento);
      });

      if (calculoValidacaoValorParcelas !== this.form.valorTotal) {
        valorDiferencaValorParcelasValorTotal =
          this.form.valorTotal - calculoValidacaoValorParcelas;
      }

      if (valorDiferencaValorParcelasValorTotal) {
        parcelasGeradas[parcelasGeradas.length - 1].valorDocumento =
          Number(parcelasGeradas[parcelasGeradas.length - 1].valorDocumento) +
          valorDiferencaValorParcelasValorTotal;
      }

      this.$emit('capturar-parcelas', parcelasGeradas);
    },
    alterarBotaoParaRecalcular() {
      this.$emit('capturar-parcelas', []);
    },
    irParaTab(tab) {
      this.$emit('alterar-tab', tab);
    },
    armazenarSelecionado(key, item) {
      this.form[`${key}Id`] = item.id;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    // adicionarValorTotal() {
    //   this.form.valorTotal = 0;
    //   if (parseFloat(this.form.valorBruto)) {
    //     this.form.valorTotal += parseFloat(this.form.valorBruto);
    //     this.validarFormulario();
    //   }
    //   if (parseFloat(this.form.frete)) {
    //     this.form.valorTotal += parseFloat(this.form.frete);
    //     this.validarFormulario();
    //   }
    //   if (parseFloat(this.valorTotalImpostos)) {
    //     this.form.valorTotal -= parseFloat(this.valorTotalImpostos);
    //   }
    // },
    // removerValorTotal() {
    //   // this.adicionarValorTotal();
    //   if (
    //     parseFloat(this.form.descontos) &&
    //     parseFloat(this.form.descontos) <= this.form.valorTotal
    //   ) {
    //     this.form.valorTotal -= parseFloat(this.form.descontos);
    //     this.validarFormulario();
    //   }
    // },
  },
};
</script>
