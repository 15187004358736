<template>
  <div>
    <form-impostos @adicionar="adicionarTabela" />
    <tabela-impostos :items="impostos" @remover="removerImposto" />
  </div>
</template>
<script>
// Components:
import FormImpostos from './Form';
import TabelaImpostos from './Tabela';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    FormImpostos,
    TabelaImpostos,
  },
  mounted() {
    if (!this.form.impostos) {
      this.form.impostos = [];
    }
    this.impostos = JSON.parse(JSON.stringify(this.form.impostos));
  },
  data() {
    return {
      impostos: [],
    };
  },
  methods: {
    adicionarTabela(item) {
      this.form.impostos = this.impostos;
      this.impostos.push(JSON.parse(JSON.stringify(item)));
      this.$emit('getImpostos', 'impostos', this.impostos);
    },
    removerImposto(item) {
      this.form.impostos = this.impostos;
      delete this.impostos[item.index];
      this.$emit('getImpostos', 'impostos', this.impostos);
    },
  },
};
</script>
