<template>
  <div class="mb-3">
    <cabecalho
      title="SUPRIMENTOS_LANCAMENTOS_NF.IMPOSTOS"
      description="SUPRIMENTOS_LANCAMENTOS_NF.NOTA_FISCAL_TAB_IMPOSTOS_DESCRICAO"
    >
      <template #default>
        <b-button :variant="btnFormImpostoVariant" @click="mostrarEsconderForm">
          {{ btnFormImpostoTexto }}
        </b-button>
      </template>
    </cabecalho>

    <div v-if="form.exibir" class="row">
      <div class="col-12 col-md-4">
        <input-select-search
          ref="dadosImposto"
          v-model="form.id"
          :label="$t(`SUPRIMENTOS.TIPO_IMPOSTO`)"
          :options="opcoes.tiposImposto"
          required
        />
      </div>
      <div class="col-12 col-md-4">
        <input-number
          ref="valor"
          v-model="form.valor"
          :label="$t('SUPRIMENTOS.VALOR')"
          decimal
          required
          min="0"
          number
        />
      </div>
      <div md="auto">
        <b-button
          class="w-100 botao-acao-filtro"
          variant="primary"
          @click="adicionar"
        >
          {{ $t('SUPRIMENTOS_LANCAMENTOS_NF.ADICIONAR_IMPOSTO') }}
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';

// Components:
import { InputNumber, InputSelectSearch } from '@/components/inputs';
import { Cabecalho } from '@/components/headers/index';

export default {
  components: {
    InputNumber,
    Cabecalho,
    InputSelectSearch,
  },
  mounted() {
    this.getImpostos();
  },
  computed: {
    btnFormImpostoTexto() {
      return this.form.exibir == false
        ? this.$t('SUPRIMENTOS_LANCAMENTOS_NF.ADICIONAR_IMPOSTO')
        : this.$t('SUPRIMENTOS_LANCAMENTOS_NF.CANCELAR_ESCONDER_FORM');
    },
    btnFormImpostoVariant() {
      return this.form.exibir == false ? 'primary' : 'secondary';
    },
  },
  data() {
    return {
      form: {
        exibir: false,
        valor: 0,
        id: '',
      },
      opcoes: {
        tiposImposto: [],
        todosEmpostos: [],
      },
    };
  },
  methods: {
    getImpostos() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-imposto')
        .then(({ data }) => {
          this.opcoes.todosEmpostos = data;
          data = data.map((row) => ({
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tiposImposto = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    adicionar() {
      if (!this.validarFormulario()) return;
      const impostoSelecionado = this.opcoes.todosEmpostos.find(
        (item) => item.id == this.form.id
      );
      if (impostoSelecionado) {
        this.$emit('adicionar', {
          valor: this.form.valor,
          tipoImposto: impostoSelecionado.descricao,
          descricao: impostoSelecionado.descricao,
          id: impostoSelecionado.id,
        });
        this.mostrarEsconderForm();
        this.form.valor = 0;
        this.form.id = '';
      }
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    mostrarEsconderForm() {
      this.form;
      return this.form.exibir == false
        ? (this.form.exibir = true)
        : (this.form.exibir = false);
    },
  },
};
</script>
