<template>
  <div>
    <cabecalho
      title="SUPRIMENTOS_LANCAMENTOS_NF.DADOS_GERAIS"
      description="SUPRIMENTOS_LANCAMENTOS_NF.NOTA_FISCAL_TAB_ITENS_DADOS_DESCRICAO"
    />
    <form-dados
      :form="form"
      @refs="capturarRefs"
      @atualiza-cnpj-fornecedor="atualizaCnpjFornecedor"
    />
  </div>
</template>
<script>
// Components:
import { Cabecalho } from '@/components/headers/index';
import FormDados from './Form';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    Cabecalho,
    FormDados,
  },
  data() {
    return {
      refs: [],
    };
  },
  methods: {
    atualizaCnpjFornecedor(cnpj) {
      this.$emit('atualiza-cnpj-fornecedor', cnpj);
    },
    capturarRefs(refs) {
      this.refs = this.$refs;
      let temp = Object.assign(this.refs, refs);
      this.refs = temp;
      this.$emit('refs', this.$refs);
    },
  },
};
</script>
