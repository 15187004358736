<template>
  <div>
    <form-itens
      @adicionar="adicionarTabela"
      :itemEditar="itemEditar"
      @atualizar="atualizaItemTabela"
    />
    <tabela-itens :items="notasFiscaisItens" @editar-itens="editarItens" />
  </div>
</template>
<script>
import FormItens from './Form';
import TabelaItens from './Tabela';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    FormItens,
    TabelaItens,
  },
  mounted() {
    if (!this.form.notasFiscaisItens) {
      this.form.notasFiscaisItens = [];
    }
    this.notasFiscaisItens = JSON.parse(
      JSON.stringify(this.form.notasFiscaisItens)
    );
  },
  data() {
    return {
      notasFiscaisItens: [],
      itemEditar: {},
      indexAtualEdicao: null,
    };
  },
  methods: {
    editarItens(item) {
      this.itemEditar = { ...item };
      this.indexAtualEdicao = item?.index;
    },
    atualizaItemTabela(item) {
      this.notasFiscaisItens.splice(this.indexAtualEdicao, 1, item);
      this.form.notasFiscaisItens = this.notasFiscaisItens;
      this.$emit('atualiza-valor-bruto');
    },
    adicionarTabela(item) {
      this.form.notasFiscaisItens = this.notasFiscaisItens;
      this.notasFiscaisItens.push(JSON.parse(JSON.stringify(item)));
      this.$emit('atualiza-valor-bruto');
    },
  },
};
</script>
