<template>
  <div>
    <h5 class="my-4">{{ $t('SUPRIMENTOS.PARCELAMENTO') }}</h5>
    <b-table
      :fields="table.fields"
      :items="parcelas"
      show-empty
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      hover
    >
      <template #cell(dataVencimento)="item">
        <template>
          <div>
            <div class="col-6 flex">
              <input-date-only-btn
                ref="dataVencimento"
                v-model="item.item.dataVencimento"
                required
              />
            </div>
          </div>
        </template>
      </template>
      <template #cell(valorDocumento)="item">
        <template>
          <div>
            <div class="col-6 flex">
              <input-number
                ref="valorDocumento"
                v-model="item.item.valorDocumento"
                required
              />
            </div>
          </div>
        </template>
      </template>
    </b-table>
  </div>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

import { InputDateOnlyBtn, InputNumber } from '@/components/inputs';

export default {
  props: {
    parcelas: { type: Array, default: Array },
  },
  components: {
    InputDateOnlyBtn,
    InputNumber,
  },
  data() {
    return {
      table: {
        fields: [
          {
            key: 'dataLancamento',
            label: this.$t('SUPRIMENTOS.DATA_LANCAMENTO'),
            formatter: (value) => {
              return value ? helpers.formatarDataBr(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'dataVencimento',
            label: this.$t('SUPRIMENTOS.DATA_VENCIMENTO'),
            formatter: (value) => {
              return value ? helpers.formatarDataBr(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'valorDocumento',
            label: this.$t('SUPRIMENTOS.VALOR'),
            formatter: (value) => {
              return value ? helpers.formatarReal(value) : '-';
            },
            sortable: true,
          },
        ],
      },
    };
  },
};
</script>
