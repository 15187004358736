<template>
  <b-table
    :fields="table.fields"
    :items="items"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="confirmarExclusao(item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="editarItem(item)">
          {{ $t('GERAL.EDITAR') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      table: {
        fields: [
          {
            key: 'produto.descricao',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.PRODUTO'),
            sortable: true,
          },
          {
            key: 'quantidade',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.QUANTIDADE'),
            sortable: true,
          },
          {
            key: 'valorUnitario',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.VALOR_UNITARIO'),
            formatter: (value) => {
              return value ? helpers.formatarReal(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'valorTotal',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.VALOR_TOTAL'),
            formatter: (value) => {
              return value ? helpers.formatarReal(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'deposito.descricao',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.DEPOSITO'),
            sortable: true,
          },
          {
            key: 'acoes',
            label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.ACOES'),
          },
        ],
      },
    };
  },
  methods: {
    editarItem(item) {
      this.$emit('editar-itens', item);
    },
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('GERAL.CONFIRMAR_EXCLUSAO'),
        this.$t('GERAL.CONFIRMAR_EXCLUSAO_ITEM').formatUnicorn({
          item: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.NOTA_FISCAL'),
        })
      );
      respostaExclusao.isConfirmed ? this.excluir(item) : {};
    },
    excluir(item) {
      this.items.splice(item.index, 1);
    },
  },
};
</script>
