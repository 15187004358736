<template>
  <modal
    id="modalItensPedido"
    titulo="SUPRIMENTOS_LANCAMENTOS_NF.ITENS_PEDIDO"
    :exibir="exibir"
    :campos="$refs"
    tamanho="xl"
    @fechar="fecharModal"
  >
    <b-row>
      <b-col md="4">
        <input-number
          ref="quantidade"
          v-model="form.quantidade"
          :label="$t('SUPRIMENTOS_LANCAMENTOS_NF.QUANTIDADE')"
          removeSpecialChars
          removeWhiteSpaces
          required
          @input="adicionarValorTotal"
        />
      </b-col>
      <b-col md="4">
        <input-number
          ref="valorUnitario"
          v-model="form.valorUnitario"
          :label="$t('SUPRIMENTOS_LANCAMENTOS_NF.VALOR_UNITARIO')"
          decimal
          required
          number
          @input="adicionarValorTotal"
        />
      </b-col>
      <b-col md="4">
        <input-number
          ref="valorTotal"
          v-model="form.valorTotal"
          :label="$t('SUPRIMENTOS_LANCAMENTOS_NF.VALOR_TOTAL')"
          decimal
          required
        />
      </b-col>
      <b-col md="6">
        <input-select-search
          ref="produtoId"
          v-model="form.produtoId"
          :label="$t(`SUPRIMENTOS_LANCAMENTOS_NF.PRODUTO`)"
          :options="opcoes.produtos"
          required
        />
      </b-col>
      <b-col md="6">
        <input-select-search
          ref="depositoId"
          v-model="form.depositoId"
          :label="$t(`SUPRIMENTOS_LANCAMENTOS_NF.DEPOSITOS`)"
          :options="opcoes.depositos"
          required
        />
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
import helpers from '@/common/utils/helpers';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import ProdutoService from '@/common/services/produtos/produtos.service';
import DepositosService from '@/common/services/depositos/depositos.service.js';
import Modal from '@/components/modal/Modal';
import { InputNumber, InputSelectSearch } from '@/components/inputs';
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    dados: { type: Object, default: Object },
  },
  components: {
    Modal,
    InputNumber,
    InputSelectSearch,
  },
  data() {
    return {
      form: {},
      opcoes: {
        produtos: [],
        depositos: [],
      },
    };
  },
  mounted() {
    this.getProdutos();
    this.getDepositos();
  },
  watch: {
    dados() {
      this.form = this.dados?.item ?? {};
    },
  },
  methods: {
    salvar() {
      if (!this.validarFormulario()) return;

      this.form.produto = this.opcoes.produtos.filter(
        (e) => e.id == this.form.produtoId
      )[0];
      this.form.deposito = this.opcoes.depositos.filter(
        (e) => e.id == this.form.depositoId
      )[0];

      !this.form.id ? this.cadastrar() : this.editar();
    },
    cadastrar() {
      this.form.id = uuidv4();
      this.$emit('atualiza-tabela', this.form);
      this.fecharModal();
    },
    editar() {
      this.$emit('atualiza-tabela-edicao', this.form);
      this.fecharModal();
    },
    adicionarValorTotal() {
      if (this.form.valorUnitario && this.form.quantidade) {
        this.form.valorTotal = this.form.valorUnitario * this.form.quantidade;
      }
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    fecharModal() {
      this.$emit('fechar', 'itens');
    },
    getProdutos() {
      this.$store.dispatch(START_LOADING);
      ProdutoService.obterTodos()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: `${row.codigo} - ${row.descricao}`,
            value: row.id,
          }));
          this.opcoes.produtos = data;
        })
        .catch((error) => {
          mensagem.erro(this.$t('GERAL.ERRO'), error.response.data.errors);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getDepositos() {
      this.$store.dispatch(START_LOADING);
      DepositosService.obterTodos()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.depositos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
