<template>
  <div class="pb-3">
    <titulo-criar-n-f />
    <b-tabs v-model="tabIndex" content-class="mt-3">
      <!-- aba 01: dados gerais -->
      <b-tab>
        <template #title>
          <b-spinner
            v-if="dadosGeraisMostrarErros"
            type="grow"
            variant="danger"
            small
          />
          {{ $t('SUPRIMENTOS_LANCAMENTOS_NF.DADOS_GERAIS') }}
        </template>
        <dados
          :form="form"
          @refs="capturarRefs"
          @atualiza-cnpj-fornecedor="atualizaCnpjFornecedor"
        />
      </b-tab>

      <!-- aba 02: itens do pedido -->
      <b-tab :disabled="disabledAbas" v-if="form.tipoNotaFiscal == 319">
        <template #title>
          <b-spinner
            v-if="itensPedidoErros"
            type="grow"
            variant="danger"
            small
          />
          {{ $t('SUPRIMENTOS_LANCAMENTOS_NF.ITENS_PEDIDO') }}
        </template>
        <itens :form="form" @atualiza-valor-bruto="atualizaValorBruto" />
      </b-tab>

      <!-- aba 03: impostos -->
      <b-tab
        :title="$t('SUPRIMENTOS_LANCAMENTOS_NF.IMPOSTOS')"
        :disabled="disabledAbas"
        v-if="
          cnpjFornecedor !== '84438209000121' &&
          cnpjFornecedor != '73284614000116'
        "
      >
        <impostos :form="form" @getImpostos="capturarItensTabela" />
      </b-tab>

      <!-- aba 04: pagamento -->
      <b-tab :disabled="disabledAbas">
        <template #title>
          <b-spinner
            v-if="financeiroErros"
            type="grow"
            variant="danger"
            small
          />
          {{ $t('SUPRIMENTOS_LANCAMENTOS_NF.PAGAMENTO') }}
        </template>
        <pagamento
          :form="form"
          :valorTotalImpostos="propsAuxiliares.valorTotalImpostos"
          :keyAtualizaValorBruto="keyAtualizaValorBruto"
          @alterar-tab="alterarTab"
        />
      </b-tab>

      <!-- aba 05: observações -->
      <b-tab :disabled="disabledAbas">
        <template #title>
          {{ $t('SUPRIMENTOS_LANCAMENTOS_NF.OBSERVACOES') }}
        </template>
        <observacoes :form="form" />
      </b-tab>
    </b-tabs>

    <tabs-btns :tabIndex="tabIndex" :ultimaTab="4" @alterar-tab="alterarTab" />

    <hr />
    <div class="float-right mb-3">
      <b-button variant="secondary" class="mr-2" @click="retornar">
        {{ $t('GERAL.CANCELAR') }}
      </b-button>
      <b-button variant="primary" @click="salvar">
        {{ $t('GERAL.SALVAR') }}
      </b-button>
    </div>
    <div
      v-if="mensagemAlertaCalcularParcelas"
      class="danger float-right mr-3 pt-2 text-danger"
    >
      <span>{{
        $t('SUPRIMENTOS_LANCAMENTOS_NF.MENSAGEM_ALERTA_CALCULAR_PARCELAS')
      }}</span>
    </div>
    <div
      v-if="mensagemAlertaValorParcelasValorNota"
      class="danger float-right mr-3 pt-2 text-danger"
    >
      <span>{{
        $t(
          'SUPRIMENTOS_LANCAMENTOS_NF.MENSAGEM_VALOR_PARCELAS_DIFERENTE_VALOR_TOTAL'
        )
      }}</span>
    </div>
    <div
      v-if="mensagemAlertaValorTotalNFIncorreto"
      class="danger float-right mr-3 pt-2 text-danger"
    >
      <span>{{
        $t('SUPRIMENTOS_LANCAMENTOS_NF.MENSAGEM_VALOR_VALOR_TOTAL_NF_INCORRETO')
      }}</span>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import breadcrumb from '@/common/utils/breadcrumb';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import rotas from '@/common/utils/rotas';

// Services:
import NotaFiscalService from '@/common/services/nota-fiscal/nota-fiscal.service.js';

// Components:
import TabsBtns from '@/components/btns/TabsBtns';
import {
  Dados,
  Impostos,
  Itens,
  Pagamento,
  Observacoes,
} from '@/views/suprimentos/lancamentos-nf/components/nota-fiscal/criar/index';

import TituloCriarNF from '@/views/suprimentos/lancamentos-nf/components/nota-fiscal/criar/TituloCriarNF.vue';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    TituloCriarNF,
    Dados,
    Impostos,
    Itens,
    Pagamento,
    Observacoes,
    TabsBtns,
  },
  data() {
    return {
      tabIndex: 0,
      keyAtualizaValorBruto: 1,
      refs: [],
      propsAuxiliares: {
        valorTotalImpostos: 'Adicione impostos para visualizar soma',
      },
      tentouSalvar: false,
      mensagemAlertaCalcularParcelas: false,
      mensagemAlertaValorParcelasValorNota: false,
      mensagemAlertaValorTotalNFIncorreto: false,
      cnpjFornecedor: null,
    };
  },
  computed: {
    disabledAbas() {
      return this.form.dataEmissao &&
        this.form.dataVencimento &&
        this.form.numeroNotaFiscal &&
        this.form.fornecedorId
        ? false
        : true;
    },
    dadosGeraisMostrarErros() {
      return this.tentouSalvar &&
        (this.refs.dataEmissao.feedback != '' ||
          this.refs.dataVencimento.feedback != '' ||
          this.refs.fornecedorId.feedback != '')
        ? true
        : false;
    },
    itensPedidoErros() {
      return this.tentouSalvar &&
        !this.form.notasFiscaisItens.length &&
        this.form.tipoNotaFiscal !== 320
        ? true
        : false;
    },
    financeiroErros() {
      return this.tentouSalvar && !this.form.notasFiscaisParcelas.length
        ? true
        : false;
    },
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('SUPRIMENTOS.SUPRIMENTOS') },
      { titulo: this.$t('SUPRIMENTOS.LANCAMENTOS_NF') },
      { titulo: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.LANCAR_NF') },
    ]);
  },
  methods: {
    atualizaValorBruto() {
      this.form.valorBruto = 0;
      if (this.form.notasFiscaisItens.length) {
        this.form.notasFiscaisItens.forEach((item) => {
          this.form.valorBruto += item.valorTotal;
        });
      }

      this.keyAtualizaValorBruto++;
    },
    atualizaCnpjFornecedor(cnpj) {
      this.cnpjFornecedor = cnpj;
    },
    validarDados() {
      this.tentouSalvar = true;
      if (!this.validarFormulario()) return false;
      if (
        (!this.form.notasFiscaisItens?.length &&
          this.form.tipoNotaFiscal !== 320) ||
        !this.form.notasFiscaisParcelas?.length
      )
        return false;

      return true;
    },
    validarValorParcelasIgualValorTotalNota() {
      let valorTotalParcelas = 0;

      this.form.notasFiscaisParcelas.forEach((item) => {
        valorTotalParcelas += Number(item.valorDocumento);
      });

      if (valorTotalParcelas != Number(this.form.valorTotal)) return false;

      return true;
    },
    validarValorTotalNF() {
      let calculoValorTotalNF =
        Number(this.form.valorBruto ? this.form.valorBruto : 0) -
        Number(
          this.form.valorTotalImpostos ? this.form.valorTotalImpostos : 0
        ) -
        Number(this.form.descontos ? this.form.descontos : 0);

      if (calculoValorTotalNF != Number(this.form.valorTotal)) return false;

      return true;
    },
    salvar() {
      this.limpaMensagensErro();
      if (this.form.notasFiscaisParcelas.length == 0) {
        this.mensagemAlertaCalcularParcelas = true;
        return;
      } else this.mensagemAlertaCalcularParcelas = false;

      if (!this.validarValorParcelasIgualValorTotalNota()) {
        this.mensagemAlertaValorParcelasValorNota = true;
        return;
      } else this.mensagemAlertaValorParcelasValorNota = false;

      if (!this.validarValorTotalNF()) {
        this.mensagemAlertaValorTotalNFIncorreto = true;
        return;
      } else this.mensagemAlertaValorTotalNFIncorreto = false;

      if (this.form.notasFiscaisParcelas.length) {
        this.form.notasFiscaisParcelas.forEach((itemNotaFiscal) => {
          itemNotaFiscal.historicoLancamentoId =
            this.form.historicoLancamento.value;
        });
      }

      if (!this.validarDados()) return;

      if (!this.form.notasFiscaisItens) {
        this.form.notasFiscaisItens = [];
      }

      this.$store.dispatch(START_LOADING);
      NotaFiscalService.criar(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('GERAL.ITEM_CRIADO').formatUnicorn({
              item: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.NOTA_FISCAL'),
            })
          );
          rotas.irPara('suprimentos-lancamentos-nf');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    limpaMensagensErro() {
      this.mensagemAlertaCalcularParcelas = false;
      this.mensagemAlertaValorParcelasValorNota = false;
      this.mensagemAlertaValorTotalNFIncorreto = false;
    },
    capturarRefs(refs) {
      this.refs = this.$refs;
      let temp = Object.assign(this.refs, refs);
      this.refs = temp;
      this.$emit('refs', this.$refs);
    },
    capturarItensTabela(key, itens) {
      this.form[key] = itens;
      if (key == 'impostos') {
        this.calcularValorTotalImpostos();
      }
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    alterarTab(tab) {
      this.tabIndex = tab;
    },
    calcularValorTotalImpostos() {
      let valorTotalImpostos = 0;
      this.form.impostos.forEach((element) => {
        valorTotalImpostos += parseFloat(element.valor);
      });
      this.propsAuxiliares.valorTotalImpostos = valorTotalImpostos.toString();
      this.form.valorTotalImpostos = Number(valorTotalImpostos);
    },
    retornar() {
      this.$router.push({ name: 'suprimentos-lancamentos-nf' });
    },
  },
};
</script>
