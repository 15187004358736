<template>
  <b-row class="mb-4">
    <b-col md="3" class="mb-2">
      <input-select-search
        ref="tipoNotaFiscal"
        v-model="form.tipoNotaFiscal"
        :label="$t(`SUPRIMENTOS_LANCAMENTOS_NF.TIPO_NOTA_FISCAL`)"
        :options="opcoes.tipoNotaFiscal"
        required
      />
    </b-col>
    <b-col md="3" class="mb-2">
      <input-date-only-btn
        ref="dataEmissao"
        v-model="form.dataEmissao"
        :label="$t('SUPRIMENTOS.DATA_EMISSAO')"
        required
      />
    </b-col>
    <b-col md="3" class="mb-2">
      <input-date-only-btn
        ref="dataVencimento"
        v-model="form.dataVencimento"
        :label="$t('SUPRIMENTOS.DATA_VENCIMENTO')"
        required
      />
    </b-col>
    <b-col md="3">
      <input-text
        ref="numeroNotaFiscal"
        v-model="form.numeroNotaFiscal"
        :label="$t('SUPRIMENTOS_LANCAMENTOS_NF.NUMERO_NOTA_FISCAL')"
        required
      />
    </b-col>
    <b-col md="12">
      <InputSelectTyping
        ref="fornecedorId"
        v-model="form.fornecedorId"
        :label="$t(`SUPRIMENTOS_LANCAMENTOS_NF.FORNECEDOR`)"
        :options="opcoes.fornecedores"
        required
        @pesquisa-nome="getFornecedores"
        :loading="loadingFornecedor"
      />
    </b-col>
  </b-row>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import EmpresaService from '@/common/services/empresa/empresa.service.js';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';

// Components:
import {
  InputDateOnlyBtn,
  InputText,
  InputSelectSearch,
  InputSelectTyping,
} from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputDateOnlyBtn,
    InputSelectSearch,
    InputText,
    InputSelectTyping,
  },
  mounted() {
    this.capturarRefs();
    this.getTipoNF();
  },
  data() {
    return {
      loadingFornecedor: false,
      refs: [],
      opcoes: {
        fornecedores: [],
        tipoNotaFiscal: [],
      },
    };
  },
  watch: {
    'form.fornecedorId'() {
      if (this.opcoes.fornecedores.length) {
        this.opcoes.fornecedores.forEach((item) => {
          if (item.value == this.form.fornecedorId) {
            this.$emit('atualiza-cnpj-fornecedor', item.cnpj);
          }
        });
      }
    },
  },
  methods: {
    getTipoNF() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-notaFiscal')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tipoNotaFiscal = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    capturarRefs(refs) {
      this.refs = this.$refs;
      let temp = Object.assign(this.refs, refs);
      this.refs = temp;
      this.$emit('refs', this.$refs);
    },
    getFornecedores(nome) {
      this.loadingFornecedor = true;
      EmpresaService.obterFornecedoresPorNome(nome)
        .then((data) => {
          this.opcoes.fornecedores = data.itens;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingFornecedor = false;
        });
    },
  },
};
</script>
