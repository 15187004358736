<template>
  <div>
    <cabecalho
      title="SUPRIMENTOS.OBSERVACOES"
      description="SUPRIMENTOS_LANCAMENTOS_NF.NOTA_FISCAL_TAB_OBSERVACOES_DESCRICAO"
    />
    <input-text-area
      ref="observacoes"
      v-model="form.observacoes"
      placeholder="SUPRIMENTOS.DIGITE_AQUI"
      :maxLength="500"
      required
    />
  </div>
</template>
<script>
// Components:
import { Cabecalho } from '@/components/headers/index';
import { InputTextArea } from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    Cabecalho,
    InputTextArea,
  },
};
</script>
