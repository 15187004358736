<template>
  <topbar
    :titulo="$t('SUPRIMENTOS_LANCAMENTOS_NF.NOTA_FISCAL')"
    :subtitulo="$t('SUPRIMENTOS_LANCAMENTOS_NF.NOTA_FISCAL_PAGE_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  >
    <template #header>
      <div
        class="d-flex flex-md-row flex-column justify-content-between ml-md-4"
      >
        <b-button @click="retornar">
          {{ $t('GERAL.VOLTAR') }}
        </b-button>
      </div>
    </template>
  </topbar>
</template>
<script>
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('SUPRIMENTOS.SUPRIMENTOS') },
        { titulo: this.$t('SUPRIMENTOS.LANCAMENTOS_NF') },
        { titulo: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.LANCAR_NF') },
      ],
    };
  },
  methods: {
    retornar() {
      this.$router.push({ name: 'suprimentos-lancamentos-nf' });
    },
  },
};
</script>
