<template>
  <div>
    <cabecalho
      title="SUPRIMENTOS_LANCAMENTOS_NF.PAGAMENTO"
      description="SUPRIMENTOS_LANCAMENTOS_NF.NOTA_FISCAL_TAB_PAGAMENTO_DESCRICAO"
    />
    <form-pagamento
      :form="form"
      :valorTotalImpostos="valorTotalImpostos"
      :keyAtualizaValorBruto="keyAtualizaValorBruto"
      @alterar-tab="irParaTab"
      @capturar-parcelas="obterParcelas"
    />
    <tabela-parcelamento v-if="parcelas.exibir" :parcelas="parcelas.dados" />
  </div>
</template>
<script>
// Components:
import { Cabecalho } from '@/components/headers/index';
import FormPagamento from './Form.vue';
import TabelaParcelamento from './Tabela.vue';

export default {
  props: {
    form: { type: Object, default: Object },
    valorTotalImpostos: { type: String, default: String },
    keyAtualizaValorBruto: { type: Number, default: 1 },
  },
  components: {
    Cabecalho,
    FormPagamento,
    TabelaParcelamento,
  },
  mounted() {
    this.form.notasFiscaisParcelas = [];
  },
  data() {
    return {
      opcoes: {
        tipoNF: [],
      },
      parcelas: {
        exibir: false,
        dados: [],
      },
    };
  },
  methods: {
    obterParcelas(data) {
      // Exibindo a tabela:
      this.parcelas.exibir = true;

      // Tratando os dados locais/auxiliares para exibição:
      this.parcelas.dados = [];
      this.parcelas.dados = data;

      // Tratando os dados para enviar ao back:
      this.form.notasFiscaisParcelas = [];
      this.form.notasFiscaisParcelas = data;
    },
    irParaTab(tab) {
      this.$emit('alterar-tab', tab);
    },
  },
};
</script>
