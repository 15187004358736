<template>
  <div class="mb-3">
    <cabecalho
      title="SUPRIMENTOS_LANCAMENTOS_NF.ITENS_PEDIDO"
      description="SUPRIMENTOS_LANCAMENTOS_NF.NOTA_FISCAL_TAB_ITENS_PEDIDO_DESCRICAO"
    >
      <template #default>
        <b-button :variant="btnFormVariant" @click="openModal('itens')">
          {{ btnFormTexto }}
        </b-button>
      </template>
    </cabecalho>

    <ModalItensPedido
      :exibir="modais.itens"
      :dados="modais.itensDados"
      @atualiza-tabela="adicionaItensTabela"
      @atualiza-tabela-edicao="adicionaItensTabelaEdicao"
      @fechar="closeModal('itens')"
    />
  </div>
</template>
<script>
import modais from '@/common/utils/modais';
import { Cabecalho } from '@/components/headers/index';
import ModalItensPedido from './ModalItensPedido.vue';

export default {
  components: {
    Cabecalho,
    ModalItensPedido,
  },
  props: {
    itemEditar: { type: Object, default: Object },
  },
  data() {
    return {
      modais: {
        itens: false,
        itensDados: {},
      },
    };
  },
  mounted() {},
  computed: {
    btnFormTexto() {
      return this.$t('SUPRIMENTOS_LANCAMENTOS_NF.ADICIONAR_ITEM');
    },
    btnFormVariant() {
      return 'primary';
    },
  },
  watch: {
    itemEditar() {
      this.modais.itensDados = this.itemEditar;
      this.openModal('itens', this.itemEditar);
    },
  },
  methods: {
    adicionaItensTabela(form) {
      this.$emit('adicionar', form);
    },
    adicionaItensTabelaEdicao(form) {
      this.$emit('atualizar', form);
    },
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
